import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Layout from '../components/layout';

const AboutPage = () => {
  return (
    <Layout styles="about" title="The Little Dolphin Academy">
      <div className="about-container">
        <div className="about-image">
          <StaticImage
            src="../images/about/becca.webp"
            alt="Becca"
            height={400}
            width={400}
            transformOptions={{
              cropFocus: 'top',
              fit: 'cover',
            }}
          />
        </div>
        <div className="about-content">
          <h2>The Team</h2>
          <p>
            The Little Dolphin Academy is owned and managed by Becca. Becca is very passionate about teaching people of
            all ages how to enjoy water safely. The Little Dolphin Academy has been running from Holyrood Secondary
            School in Glasgow since 2016. We aim to provide the best teaching, whilst encouraging our swimmers to have
            fun and be safe in the water.
          </p>
        </div>
      </div>
      <div className="about-container">
        <div className="about-image">
          <StaticImage
            src="../images/about/staff.jpg"
            alt="Staff"
            height={400}
            width={400}
            transformOptions={{
              cropFocus: 'top',
              fit: 'cover',
            }}
          />
        </div>
        <div className="about-content">
          <h2>Our Instructors</h2>
          <p>
            All our instructors are qualified swimming teachers, many have competed at club level and are eager to pass
            on their expertise. With great enthusiasm and passion, they help all of our swimmers to achieve their goals.
          </p>
        </div>
      </div>
      <div className="about-container">
        <div className="about-image">
          <StaticImage
            src="../images/about/pool.webp"
            alt="Pools"
            height={400}
            width={400}
            transformOptions={{
              cropFocus: 'bottom',
              fit: 'cover',
            }}
          />
        </div>
        <div className="about-content">
          <h2>Our Pools</h2>
          <p>
            The Little Dolphin Academy runs from two swimming pools, Holyrood Secondary School and St Paul's High
            School. Both pools are supervised by a qualified lifeguard during all sessions. Both school pools are
            currently closed due to coronavirus restrictions but we are hoping to reopen both ASAP.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
